
import React, { Component } from "react";
import { Link, withRouter } from "react-router-dom";

import { connect } from "react-redux";
import { logoutUser,clearStorage,showSidebar,hideSidebar,resetState ,getUserForAuth } from "../../actions/authAction";
import { openModal } from "../../actions/modalAction";

import {   Dropdown ,
 Button,
  Icon,
  } from "semantic-ui-react";
//import {imageURL} from "../configC/keys";


class Navbar extends Component {
  
  componentDidMount() {
    if (!this.props.state.auth.userA){
      this.props.getUserForAuth();
        }

  }

  

  onLogoutClick = () => {
this.props.history.push(`/`)
    this.props.logoutUser();

  };
  //*******************************************
  handleSidebarToggle=()=>{
  this.props.showSidebar();
  window.scroll(0,0)
  }

  //*************** */
  backButtonHandler =()=>{
    this.props.history.goBack()
    this.props.resetState()
  }

  
 // *****************************************

  render() {
    //const { open,  } = this.state;
    const { isAuthenticated, user } = this.props.auth;
    //const userA= this.props.userA !== undefined && this.props.userA !== null && this.props.userA
    const renderRefreshButton =()=>{
      return  (
        <Button
        circular
        icon='refresh'
        onClick={() => window.location.reload(false)}
        //onClick={this.handleOpen}
        style={{backgroundColor: 'dodgerblue', color:'white'}}
      />
      ) }
    const renderBackButton =()=>{
      return this.props.visibleBB=== true ? (
      <button

      onClick={this.backButtonHandler}

      className='back-btn'
      >
      <Icon name="arrow left" />

      </button>
      ) : (
      <div></div>
      )}
      
          //########################################

    const authLinks = (
      <div>





<div>{/* nav mobile start*/}
<nav id="navbar-top" className='border-bottom-nav' style={{backgroundColor:'white',flexDirection:'row'}}>



<div style={{display:'flex'}}>

<div style={{display:'flex',alignItems:'center'}}>
<span className = ' mb-05 mr-10'
    style={{display: 'flex',flexDirection:'row',alignItems:'center'}} 
    >
    <img className= ' ' src='/logo_ekhata.png' alt=" "  style={{width:"60px", height:"39px",borderRadius:'5px',verticalAlign:'middle'}} />
    
      {/* <Image size='mini' src='/logo_ekhata.png'  /> */}
      </span>
<div className='nodisplay' style={{fontSize:'1.2rem',fontWeight:'bold'}}>
<span style={{color:'dodgerblue'}}>ADMIN.</span>
  <span style={{color:'pink'}}>E</span>
  <span style={{color:'skyblue'}}>K</span>
  <span style={{color:'orange'}}>H</span>
  <span style={{color:'pink'}}>A</span>
  <span style={{color:'skyblue'}}>T</span>
  <span style={{color:'orange'}}>A</span>
  <span style={{color:'dodgerblue'}}>.IN</span>
  </div>
</div>


{/* <div>FindRealEstate</div> */}

</div>


<ul>

<li>
{renderRefreshButton()}
  </li>



<li>{renderBackButton()}</li>
{/*<li>{renderShowHideSidebarButton()}</li>*/}
<li>
   <Link to={'/'} >

<div style={{fontSize:'2.0rem',fontWeight:'bold',marginRight:'10px',padding:'5px'}}><Icon name='home' /></div>
</Link>

</li>


<li style={{fontSize:'1.5rem',fontWeight:'bold',marginRight:'10px',padding:'5px',color:'blue'}}>

<Dropdown
pointing="top right"
//text={user.name}
icon='user outline'
>
  <Dropdown.Menu>
  <Dropdown.Item
          style={{fontWeight:'bold',cursor:'auto'}}
        ><div className="font-18B color-blue">{user.name}{' '}</div>
        </Dropdown.Item>

        <Dropdown.Item
          onClick={this.onLogoutClick}
        > 
        <span className="font-18B color-red">
          <Icon name="power"/>
           Sign Out
           </span>
          </Dropdown.Item>

          <Dropdown.Item
            as={Link}
            to="/auth/passchange"
          >
          <span className="font-18B color-dodgerblue">
          Change Password
           </span>
          </Dropdown.Item>
  
  </Dropdown.Menu>

</Dropdown>

{/*<button className='btn' style={{fontSize:'1.2rem',fontWeight:'bold'}}  onClick={this.handleOpenPop}

><Icon name="user outline" /></button>

 <div style={{fontSize:'1.2rem',fontWeight:'bold'}}><Icon name='user outline' />Profile|Activity</div>
*/}


</li>



</ul>

      </nav>

</div>{/* nav mobile end*/}
      </div>
    );
    const guestLinks = (

      <div >
      <nav id="navbar" className="mastheadNAV">
      <div style={{display:'flex',alignItems:'center',marginLeft:'10px'}}>
      <span className = ' mb-05 mr-10 '
    style={{display: 'flex',flexDirection:'row',alignItems:'center'}} 
    >
    <img className= ' ' src='/logo_ekhata.png' alt=" "  style={{width:"60px", height:"39px",borderRadius:'5px',verticalAlign:'middle'}} />
    
      {/* <Image size='mini' src='/logo_ekhata.png'  /> */}
      </span>
<div className='' style={{fontSize:'1.2rem',fontWeight:'bold'}}>
<span style={{color:'aqua'}}>ADMIN.</span>
  <span style={{color:'pink'}}>E</span>
  <span style={{color:'skyblue'}}>K</span>
  <span style={{color:'orange'}}>H</span>
  <span style={{color:'pink'}}>A</span>
  <span style={{color:'skyblue'}}>T</span>
  <span style={{color:'orange'}}>A</span>
  <span style={{color:'aqua'}}>.IN</span>
</div>
</div>
          <ul>
            <li><b><a href="https://admin.ekhata.in">Home</a></b></li>

            

          </ul>
        </nav>

      </div>
    );



    return (
      <div>
        {isAuthenticated ? (
          authLinks
        ) : (
          guestLinks
        )}
      </div>
    );
  }
}
//@@@@@@@@@@@@@@@@@



const mapStateToProps = state => {
  return {
    state,
  auth: state.auth,
  visible: state.auth.visible,
  visibleBB: state.auth.visibleBB,
  userA: state.auth.userA,
}
};

export default connect(
  mapStateToProps,
  { logoutUser,clearStorage, openModal,showSidebar,hideSidebar ,resetState ,getUserForAuth }
)(withRouter(Navbar));
