import React, {Component} from 'react';

import { connect } from "react-redux";
import { hideBackButton,getUserForAuth,logoutUser } from "../../actions/authAction";
//import { Link } from 'react-router-dom';
import './css/border.css';
import './css/button.css';
import './css/card.css';
import './css/ribbon.css';
import './css/ribbon2.css';
import './css/common.css';
import './css/color.css';
import './css/container.css';
import './css/display.css';
import './css/grid.css';
import './css/home.css';
import './css/mis.css';
import './css/nav.css';
import './css/sidebar.css';
 import './css/checkbox.css';
// import './styleFRE.css';
import './menu2/menu.css';
 import './styleK.css';


import { openModal } from "../../actions/modalAction";
//import Layout from "../layout/Layout";
// import { 
//   //Button,  
//   //Icon, 
//   Image } from 'semantic-ui-react'
  import LinkItem from './LinkItem'
  import LinkItem_1 from './LinkItem_1'

class Landing extends Component {
  
  componentDidMount() {
    
    if(this.props.history.location.pathname ==='/'){
      this.props.hideBackButton()
    }

  }
  onLogoutClick = e => {
    e.preventDefault();
    this.props.logoutUser();
  };
  



//****************** */
handleLoginModal = () => {
  this.props.openModal('LoginModal');
};

//*************** */

render() {

  const { isAuthenticated,  } = this.props.auth;
const userA= this.props.userA !== undefined && this.props.userA !== null && this.props.userA
  // const able = 'sidebar-border-divider home-div-link'
  // const disable = 'sidebar-border-divider home-div-link-disabled'

const userRouteHandler = ()=>{
   
      return(
        <>
        <LinkItem
        url={"/user/UserSearchList"}
        title={'All Users'}
        />

        <LinkItem_1
        url={"/entries/TotalEntriesAllCompany"}
        title={'Total Entries Of All Companies'}
        />

        
</>
          )
    
    }
      // const companyRouteHandler = ()=>{
      //   // const moduleAbleDisable = (this.props.auth.userA !== null && this.props.auth.userA.note_contactModule === "Yes"  )?
      //   //    able:disable
      //   return (
      //     <div>
      //     <div className={able}>  
      //     <Link to="/user/UserSearchList" >
      //     <b>All Users</b><span className='icon-angle' ><Icon name="angle right" /></span>
      //     </Link>
      //     </div>

          
      //     <div className={able}> 
      //     <Link to="/appParameter/AppParameterDetail" >
      //     <b>App Parameter Detail</b><span className='icon-angle' ><Icon name="angle right" /></span>
      //     </Link>
      //     </div>

      //     <div className={able}> 
      //     <Link to="/appParameter/URLCreate" >
      //     <b>Create URL</b><span className='icon-angle' ><Icon name="angle right" /></span>
      //     </Link>
      //     </div>

      //     <div className={able}> 
      //     <Link to="/entries/DeleteEntries" >
      //     <b>Delete Entries</b><span className='icon-angle' ><Icon name="angle right" /></span>
      //     </Link>
      //     </div>

      //     <div className={able}> 
      //     <Link to="/entries/TotalEntriesAllCompany" >
      //     <b>Total Entries Of All Companies</b><span className='icon-angle' ><Icon name="angle right" /></span>
      //     </Link>
      //     </div>

      //     <div className={able}>  
      //     <Link to="/user/UserCreate" >
      //     <b>Create User</b><span className='icon-angle' ><Icon name="angle right" /></span>
      //     </Link>
      //     </div>

      //     </div>
      //         )
      // }



      const authHome = (
        <div className="background-link-home  body " style={{marginTop:'50px',height: '100vh'}}>
        
               
      <div className='py-3 disflex-center'>{/* start card*/}
        
      
      <div className='disflex-start direction-column-atMobile ' >
      {userRouteHandler()}
          </div>
          
          
          
      
         
      </div>
      </div>  
       
      
      
      )
      



// const authHome = (
//   <div className="background-home  body " style={{marginTop:'50px'}}>
//   <div  style={{maxWidth:'700px',margin:'auto',textAlign:'left'}}>

//   <div style={{display:'flex', justifyContent:'center', padding:'10px'}}>
// <div className='card-home  background-home-link  '>{/* start card*/}


//     {userRouteHandler()}



// </div>
// </div>  {/* end card/segment*/}

//   </div>
//   </div>

// )



const guestHome = (
  <div style={{marginTop:'50px'}}>


    
    <div id="mainP">
{/* Header: Showcase */}

<section id="showcase" className="masthead ">
  <div className="showcase-content" >
  
  { this.props.auth !== undefined && !this.props.auth.isAuthenticated &&  (
               
               
               <div style={{display:'flex'}}>
               <div
         
         
                 onClick={this.handleLoginModal}
                 className="btnlogin  pxy-5-10"
               >
               login
                
               </div>
               
               </div>
               
               )
               }
  </div>

</section>







</div> 


{/* Footer */}
<footer id="main-footer" className="bg-dark text-center py-1">
<div className="containerP">
<div className="row" style={{border:'none'}}>
<div className="disflex-nav-footer" style={{ display: 'flex',flex: '50%',padding: 20,flexDirection:'column'}}>



<span className = ' mb-05 '
    style={{display: 'flex',flexDirection:'row',alignItems:'center'}} 
    >
    <img className= ' ' src='/logo_ekhata.png' alt=" "  style={{width:"80px", height:"60px",borderRadius:'5px',verticalAlign:'middle'}} />
    
      {/* <Image size='mini' src='/logo_ekhata.png'  /> */}
      </span>
      
  {/*  */}
  <div className='' style={{fontSize:'1.2rem',fontWeight:'bold'}}>
  <span style={{color:'aqua'}}>ADMIN.</span>
  <span style={{color:'pink'}}>E</span>
  <span style={{color:'white'}}>K</span>
  <span style={{color:'orange'}}>H</span>
  <span style={{color:'pink'}}>A</span>
  <span style={{color:'white'}}>T</span>
  <span style={{color:'orange'}}>A</span>
  <span style={{color:'aqua'}}>.IN</span>
  </div>


{/*  */}
</div>





</div>
<p > © Copyright 2020 CREDIBLE ITSYS, All Rights Reserved</p>
<br/>
</div>
</footer>


{/*  */}
  </div>
);
const renderRoute =()=>{
  if(isAuthenticated && ((userA.userType === 'admin')||(userA.userType === 'executive'))){
    return authHome
  }

  if(!isAuthenticated){
    return guestHome
  }
}
  return(
    <div>
      {renderRoute()}
    </div>
  );
}
}
const mapStateToProps = state => ({
  auth: state.auth,
  visible: state.auth.visible,
  visibleBB: state.auth.visibleBB,
  userA: state.auth.userA,
});

export default connect(
  mapStateToProps,
  {openModal, logoutUser,hideBackButton,getUserForAuth  }
)(Landing);
//export default Landing;
